import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Clark | François Deguire' />
    <ProjectPage
      name='Clark'
      description={`
        This swing chair was created as part of a contest for the Clark esplanade
        in Montréal. The goal was to create playful furniture that would integrate
        well with the future public space and outdoor ice rink. The chair integrates
        the visual identity of the Quartier Des Spectacles in its design and is
        created to double as a skating learning aid in the winter when the public
        space transforms itself in an ice rink.
      `}
      meta={`
        For the City of Montréal<br>
        In 2018
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    clark1: file(relativePath: { eq: "clark/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clark2: file(relativePath: { eq: "clark/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clark3: file(relativePath: { eq: "clark/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clark4: file(relativePath: { eq: "clark/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
